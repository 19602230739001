import React from "react";
import Img from "gatsby-image";
import { css } from "@emotion/core";
import { graphql } from "gatsby";
import Title from "../components/title";
import Layout from "../layout/site-layout";
import Return from "../components/return-button";
import SEO from "../components/seo";
import Text from "../components/text";
import { useIntl } from "gatsby-plugin-intl";

export const query = graphql`
  query Images {
    image1: file(name: { eq: "aip_05" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image2: file(name: { eq: "aip_12" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image3: file(name: { eq: "aip_07" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image4: file(name: { eq: "aip_08" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image5: file(name: { eq: "aip_09" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    image7: file(name: { eq: "aip_13" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image8: file(name: { eq: "aip_04" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image9: file(name: { eq: "aip_11" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const Progress = ({ data }) => {
  const intl = useIntl();

  return (
    <Layout>
      <SEO title="Art in Progress" />
      <Title text="Art in Progress" />
      <Text>{intl.formatMessage({ id: "aip.text_1" })}</Text>
      <Text>{intl.formatMessage({ id: "aip.text_2" })}</Text>
      <Text>{intl.formatMessage({ id: "aip.text_3" })}</Text>
      <div
        css={css`
          display: grid;
          grid-gap: 1rem;
          margin-top: 2rem;

          @media screen and (min-width: 768px) {
            grid-gap: 2rem;
          }
        `}
      >
        <Img fluid={data.image1.childImageSharp.fluid} />
        <Img fluid={data.image2.childImageSharp.fluid} />
        <Img fluid={data.image3.childImageSharp.fluid} />
        <Img fluid={data.image4.childImageSharp.fluid} />
        <Img fluid={data.image5.childImageSharp.fluid} />
        <Img fluid={data.image7.childImageSharp.fluid} />
        <Img fluid={data.image8.childImageSharp.fluid} />
        <Img fluid={data.image9.childImageSharp.fluid} />
      </div>
      <Return />
    </Layout>
  );
};
export default Progress;
